import React, { memo } from "react";
import PropTypes from "prop-types";
import CompletionMessage from "./CompletionMessage";

const SmileyFeedback = ({
  handleRating,
  feedback = {},
  questions = [],
  feedbackUpdating,
  showCompletion,
}) => {
  const firstQuestion = questions?.[0];

  return (
    <div
      className={`feedback-section ${feedbackUpdating ? "animation" : ""} ${
        showCompletion ? "completion" : ""
      }`}
    >
      {!showCompletion && firstQuestion ? (
        <>
          <div className="item feedback-item">
            <div className="feedback-item__header">
              <h4>{feedback?.title}</h4>
            </div>
            <div className="feedback-item__body">
              <div className="feedback-item__body--question">
                <p>{firstQuestion?.question}</p>
              </div>
              <div className="feedback-item__body--rating">
                <ul className="smiley-container">
                  <li className="smiley">
                    <button
                      type="button"
                      className="smiley-button"
                      onClick={() => handleRating(firstQuestion?.id, 1)}
                    >
                      <img
                        src={require("../../assets/images/emoji/pouting-face.png")}
                        alt="pouting-face emoji"
                      />
                    </button>
                  </li>
                  <li className="smiley">
                    <button
                      type="button"
                      className="smiley-button"
                      onClick={() => handleRating(firstQuestion?.id, 2)}
                    >
                      <img
                        src={require("../../assets/images/emoji/frowning-face.png")}
                        alt="frowning-face emoji"
                      />
                    </button>
                  </li>
                  <li className="smiley">
                    <button
                      type="button"
                      className="smiley-button"
                      onClick={() => handleRating(firstQuestion?.id, 3)}
                    >
                      <img
                        src={require("../../assets/images/emoji/neutral-face.png")}
                        alt="neutral-face emoji"
                      />
                    </button>
                  </li>
                  <li className="smiley">
                    <button
                      type="button"
                      className="smiley-button"
                      onClick={() => handleRating(firstQuestion?.id, 4)}
                    >
                      <img
                        src={require("../../assets/images/emoji/grinning-face.png")}
                        alt="grinning-face emoji"
                      />
                    </button>
                  </li>
                  <li className="smiley">
                    <button
                      type="button"
                      className="smiley-button"
                      onClick={() => handleRating(firstQuestion?.id, 5)}
                    >
                      <img
                        src={require("../../assets/images/emoji/smiling-face-with-heart-eyes.png")}
                        alt="smiling-face-with-heart-eyes emoji"
                      />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="questions-dots">
            <ul>
              {questions?.map((question, index) => (
                <li key={question?.id}>
                  <button
                    type="button"
                    className={`dot ${index === 0 ? "active" : ""}`}
                  />
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <>{showCompletion ? <CompletionMessage feedback={feedback} /> : null}</>
      )}
    </div>
  );
};
SmileyFeedback.propTypes = {
  handleRating: PropTypes.func.isRequired,
  feedback: PropTypes.object,
  questions: PropTypes.array,
  feedbackUpdating: PropTypes.bool,
  showCompletion: PropTypes.bool,
};

export default memo(SmileyFeedback);
